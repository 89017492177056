import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 1080,
    background: theme.palette.background.default,
  },
  navajo: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  main: {
    paddingBottom: 80,
  },
}));

const App = ({ children, isDarkMode, updateDarkMode }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar isDarkMode={isDarkMode} updateDarkMode={updateDarkMode} />

      <main className={classes.main}>{children}</main>

      <Footer />
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
