import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import WbSunnyIcon from "@material-ui/icons/WbSunny";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    "& .MuiPaper-root": {
      background: theme.palette.background.default,
    },
  },
  list: {
    width: 250,
    background: theme.palette.background.default,
  },
  listItem: {
    background: theme.palette.background.default,
  },
  listItemText: {
    background: theme.palette.background.default,
    "& .MuiTypography-body1": {
      ...theme.typography.button,
      color: theme.palette.text.primary,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const NatbarMobile = props => {
  const classes = useStyles(props);
  const { isDarkMode, setIsDarkMode, handleNavigate } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);

  const handleNaviageTo = to => {
    setDrawerOpen(false);
    handleNavigate(to);
  };

  const handleSetDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSetFeaturesOpen = () => {
    setFeaturesOpen(!featuresOpen);
  };

  const handleSetResourcesOpen = () => {
    setResourcesOpen(!resourcesOpen);
  };

  return (
    <div className={classes.root}>
      {/* <IconButton aria-label="search" color="inherit" onClick={e => handleNavigate("/search")}>
        <SearchIcon />
      </IconButton> */}
      <IconButton aria-label="toggle dark mode" color="inherit" onClick={setIsDarkMode}>
        {isDarkMode ? <WbSunnyIcon /> : <Brightness2Icon />}
      </IconButton>
      <IconButton aria-label="search" edge="end" color="inherit" onClick={handleSetDrawerOpen}>
        <MenuOpenIcon />
      </IconButton>

      <Drawer className={classes.drawer} anchor="right" open={drawerOpen} onClose={handleSetDrawerOpen}>
        <div className={classes.list}>
          <List>
            <ListItem className={classes.listItem} button onClick={e => handleNaviageTo("/")}>
              <ListItemText className={classes.listItemText} primary={"Home"} />
            </ListItem>
            <Divider />
            <ListItem className={classes.listItem} button onClick={handleSetFeaturesOpen}>
              <ListItemText className={classes.listItemText} primary={"Features"} />
              {featuresOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={featuresOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#templates-tab")}>
                  <ListItemText className={classes.listItemText} primary={"Templates"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#preview-tab")}>
                  <ListItemText className={classes.listItemText} primary={"Preview"} />
                </ListItem>
                <ListItem
                  className={clsx(classes.nested, classes.listItem)}
                  button
                  onClick={e => handleNaviageTo("/#device-one-device-two")}
                >
                  <ListItemText className={classes.listItemText} primary={"Device One & Two"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#layout-picker")}>
                  <ListItemText className={classes.listItemText} primary={"Layout"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#panoramic-tab")}>
                  <ListItemText className={classes.listItemText} primary={"Panoramic"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#title-subtitle")}>
                  <ListItemText className={classes.listItemText} primary={"Title & Subtitle"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#font-picker")}>
                  <ListItemText className={classes.listItemText} primary={"Font Picker"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#icon")}>
                  <ListItemText className={classes.listItemText} primary={"Icon"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#background")}>
                  <ListItemText className={classes.listItemText} primary={"Background"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/#export")}>
                  <ListItemText className={classes.listItemText} primary={"Export"} />
                </ListItem>
              </List>
            </Collapse>
            <Divider />
            <ListItem className={classes.listItem} button onClick={e => handleNaviageTo("/examples")}>
              <ListItemText className={classes.listItemText} primary={"Examples"} />
            </ListItem>
            <Divider />
            <ListItem className={classes.listItem} button onClick={handleSetResourcesOpen}>
              <ListItemText className={classes.listItemText} primary={"Resources"} />
              {resourcesOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={resourcesOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/blog")}>
                  <ListItemText className={classes.listItemText} primary={"Blog"} />
                </ListItem> */}
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/tutorials")}>
                  <ListItemText className={classes.listItemText} primary={"Tutorials"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/faq")}>
                  <ListItemText className={classes.listItemText} primary={"FAQ"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/about")}>
                  <ListItemText className={classes.listItemText} primary={"About"} />
                </ListItem>
                <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/contact")}>
                  <ListItemText className={classes.listItemText} primary={"Contact"} />
                </ListItem>
                {/* <ListItem className={clsx(classes.nested, classes.listItem)} button onClick={e => handleNaviageTo("/press")}>
                  <ListItemText className={classes.listItemText} primary={"Press"} />
                </ListItem> */}
              </List>
            </Collapse>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default NatbarMobile;
