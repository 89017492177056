import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
      flex: "unset",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: "1",
    },
  },
  link: {
    padding: "10px 16px",
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    ...theme.typography.button,
  },
  menuButton: {
    background: theme.palette.background.default,
    "&:hover": {
      textDecoration: "none",
      background: theme.palette.background.default,
    },
  },
  startNowButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "10px 20px",
    borderRadius: "8px",
    marginLeft: "24px",
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
      boxShadow: () => {
        return theme.palette.type === "light"
          ? `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`
          : `rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px`;
      },
    },
  },
  menuItem: {
    ...theme.typography.button,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
}));

const NavbarDesktop = props => {
  const classes = useStyles(useStyles);
  const [featuresElement, setFeaturesElement] = useState(null);
  const [resourcesElement, setResourcesElement] = useState(null);
  const { isDarkMode, setIsDarkMode, handleNavigate } = props;

  // Features
  const handleClickFeatures = e => {
    setFeaturesElement(e.currentTarget);
  };

  const handleCloseFeatures = () => {
    setFeaturesElement(null);
  };

  const handleClickTemplates = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Templates",
      value: 0,
    });

    handleNavigate("/#templates-tab");
    setFeaturesElement(null);
  };

  const handleClickStorePreview = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Store Preview",
      value: 0,
    });

    handleNavigate("/#preview-tab");
    setFeaturesElement(null);
  };

  const handleClickDevice = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Device",
      value: 0,
    });

    handleNavigate("/#device-one-device-two");
    setFeaturesElement(null);
  };

  const handleClickLayouts = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Layouts",
      value: 0,
    });

    handleNavigate("/#layout-picker");
    setFeaturesElement(null);
  };

  const handleClickPanoramic = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Panoramic",
      value: 0,
    });

    handleNavigate("/#panoramic-tab");
    setFeaturesElement(null);
  };

  const handleClickTitleSubtitle = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Title & Subtitle",
      value: 0,
    });

    handleNavigate("/#title-subtitle");
    setFeaturesElement(null);
  };

  const handleClickFontPicker = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Font Picker",
      value: 0,
    });

    handleNavigate("/#font-picker");
    setFeaturesElement(null);
  };

  const handleClickIcon = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Icon",
      value: 0,
    });

    handleNavigate("/#icon");
    setFeaturesElement(null);
  };

  const handleClickBackground = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Background",
      value: 0,
    });

    handleNavigate("/#background");
    setFeaturesElement(null);
  };

  const handleClickExport = e => {
    trackCustomEvent({
      category: "Features Desktop",
      action: "Click",
      label: "Export",
      value: 0,
    });

    handleNavigate("/#export");
    setFeaturesElement(null);
  };

  // Resources
  const handleClickResources = e => {
    setResourcesElement(e.currentTarget);
  };

  const handleCloseResources = e => {
    setResourcesElement(null);
  };

  // const handleClickBlog = e => {
  //   trackCustomEvent({
  //     category: "Resources Desktop",
  //     action: "Click",
  //     label: "Blog",
  //     value: 0,
  //   });

  //   handleNavigate("/blog");
  //   setResourcesElement(null);
  // };

  const handleClickTutorials = e => {
    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "Tutorials",
      value: 0,
    });

    handleNavigate("/tutorials");
    setResourcesElement(null);
  };

  const handleClickFAQ = e => {
    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "FAQ",
      value: 0,
    });

    handleNavigate("/faq");
    setResourcesElement(null);
  };

  // const handleClickPress = e => {
  //   trackCustomEvent({
  //     category: "Resources Desktop",
  //     action: "Click",
  //     label: "Press",
  //     value: 0,
  //   });

  //   handleNavigate("/press");
  //   setResourcesElement(null);
  // };

  const handleClickAbout = e => {
    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "About Us",
      value: 0,
    });

    handleNavigate("/about");
    setResourcesElement(null);
  };

  // const handleClickWhatsNew = e => {
  //   trackCustomEvent({
  //     category: "Resources Desktop",
  //     action: "Click",
  //     label: "What's New",
  //     value: 0,
  //   });

  //   handleNavigate("/whats-new");
  //   setResourcesElement(null);
  // };

  const handleClickContact = e => {
    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "Contact",
      value: 0,
    });

    handleNavigate("/contact");
    setResourcesElement(null);
  };

  // const handleClickFreeStuff = e => {
  //   trackCustomEvent({
  //     category: "Resources Desktop",
  //     action: "Click",
  //     label: "Feww Stuff",
  //     value: 0,
  //   });

  //   handleNavigate("/downloads");
  //   setResourcesElement(null);
  // };

  return (
    <div className={classes.root}>
      <div style={{ flex: "1" }}></div>

      <div style={{ display: "flex" }}>
        <Button
          className={clsx(classes.link, classes.linkTitle, classes.menuButton)}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClickFeatures}
          disableRipple={true}
        >
          Features
        </Button>
        <Menu
          className={classes.menu}
          anchorEl={featuresElement}
          keepMounted
          open={Boolean(featuresElement)}
          onClose={handleCloseFeatures}
          transitionDuration={0}
        >
          <MenuItem className={classes.menuItem} onClick={handleClickTemplates}>
            Templates
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickStorePreview}>
            Store Preview
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickDevice}>
            Device 1 & 2
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickLayouts}>
            Layouts
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickPanoramic}>
            Panoramic
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickTitleSubtitle}>
            Title & Subtitle
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickFontPicker}>
            Font Picker
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickIcon}>
            Icon
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickBackground}>
            Background
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickExport}>
            Export
          </MenuItem>
        </Menu>

        <Link className={classes.link} to="/examples">
          <Typography className={classes.linkTitle} variant="subtitle1">
            Examples
          </Typography>
        </Link>

        <Button
          className={clsx(classes.link, classes.linkTitle, classes.menuButton)}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClickResources}
          disableRipple={true}
        >
          Resources
        </Button>
        <Menu
          className={classes.menu}
          anchorEl={resourcesElement}
          keepMounted
          open={Boolean(resourcesElement)}
          onClose={handleCloseResources}
          transitionDuration={0}
        >
          {/* <MenuItem className={classes.menuItem} onClick={handleClickBlog}>
            Blog
          </MenuItem> */}
          <MenuItem className={classes.menuItem} onClick={handleClickTutorials}>
            Tutorials
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClickFAQ}>
            FAQ
          </MenuItem>
          {/* <MenuItem className={classes.menuItem} onClick={handleClickPress}>
            Press
          </MenuItem> */}
          <MenuItem className={classes.menuItem} onClick={handleClickAbout}>
            About
          </MenuItem>
          {/* <MenuItem className={classes.menuItem} onClick={handleClickWhatsNew}>
            What's New
          </MenuItem> */}
          <MenuItem className={classes.menuItem} onClick={handleClickContact}>
            Contact
          </MenuItem>
        </Menu>

        <IconButton aria-label="toggle dark mode" edge="end" color="inherit" onClick={setIsDarkMode}>
          {isDarkMode ? <WbSunnyIcon /> : <Brightness2Icon />}
        </IconButton>
      </div>
    </div>
  );
};

export default NavbarDesktop;
