import React from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: "unset",
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      minHeight: "80px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "96px",
    },
  },
  logo: {
    height: 48,
    width: 48,
    borderRadius: 4,
  },
  logoTitle: {
    marginLeft: 16,
    fontWeight: "600",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      background: theme.palette.background.default,
    },
  },
}));

const Navbar = props => {
  const { isDarkMode, updateDarkMode } = props;
  const classes = useStyles(props);

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "appmockup-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleUpdateDarkMode = e => {
    updateDarkMode(!isDarkMode);
  };

  const handleNavigate = to => {
    navigate(to);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <Img className={classes.logo} fluid={data.placeholderImage.childImageSharp.fluid} title="AppMockUp Logo" alt="AppMockUp Logo" />
          </Link>
          <Link to="/" className={classes.logoTitle}>
            <Typography className={classes.linkTitle} variant="h5">
              <strong style={{ fontWeight: "600", fontSize: "1.5rem" }}>AppMockUp</strong>
            </Typography>
          </Link>

          <NavbarDesktop isDarkMode={isDarkMode} setIsDarkMode={handleUpdateDarkMode} handleNavigate={handleNavigate} />
          <NavbarMobile isDarkMode={isDarkMode} setIsDarkMode={handleUpdateDarkMode} handleNavigate={handleNavigate} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
