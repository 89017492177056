import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 128,
    marginBottom: 128,
  },
  footerContainer: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  footerGrid: {
    [theme.breakpoints.up("md")]: {
      gridItem: {
        flexDirection: "column",
      },
    },
  },
  footerTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  footerLink: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const Footer = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container className={classes.footerContainer} maxWidth="lg">
        <Grid container spacing={2}>
          {/* Company */}
          <Grid item xs={12} sm={3}>
            <div style={{ marginBottom: 16 }}>
              <Typography className={classes.footerTitle} variant="subtitle2">
                <strong>Company</strong>
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography className={clsx(classes.footerLink, classes.footerTitle)} onClick={() => navigate("/about")} variant="subtitle2">
                About Us
              </Typography>
            </div>
            {/* <div style={{ marginBottom: 16 }}>
              <Typography className={clsx(classes.footerLink, classes.footerTitle)} onClick={() => navigate("/press")} variant="subtitle2">
                Press
              </Typography>
            </div> */}
            <div style={{ marginBottom: 16 }}>
              <Typography
                className={clsx(classes.footerLink, classes.footerTitle)}
                onClick={() => navigate("/contact")}
                variant="subtitle2"
              >
                Contact
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography className={clsx(classes.footerLink, classes.footerTitle)} onClick={() => navigate("/terms")} variant="subtitle2">
                Terms & Conditions
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <a
                className={clsx(classes.footerLink, classes.footerTitle)}
                href="https://www.iubenda.com/privacy-policy/12958209"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">Privacy Policy</Typography>
              </a>
            </div>
            <div style={{ marginBottom: 16 }}>
              <a
                className={clsx(classes.footerLink, classes.footerTitle)}
                href="http://app-mockup.com/sitemap.xml"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">Sitemap</Typography>
              </a>
            </div>
          </Grid>

          {/* Social Media */}
          <Grid item xs={12} sm={3}>
            <div style={{ marginBottom: 16 }}>
              <Typography className={classes.footerTitle} variant="subtitle2">
                <strong>Social Media</strong>
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <a
                className={clsx(classes.footerLink, classes.footerTitle)}
                href="http://facebook.com/appmockup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">Facebook</Typography>
              </a>
            </div>
            <div style={{ marginBottom: 16 }}>
              <a
                className={clsx(classes.footerLink, classes.footerTitle)}
                href="http://twitter.com/appmockup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">Twitter</Typography>
              </a>
            </div>
            <div style={{ marginBottom: 16 }}>
              <a
                className={clsx(classes.footerLink, classes.footerTitle)}
                href="http://instagram.com/appmockup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">Instagram</Typography>
              </a>
            </div>
          </Grid>

          {/* Services */}
          <Grid item xs={12} sm={3}>
            <div style={{ marginBottom: 16 }}>
              <Typography className={classes.footerTitle} variant="subtitle2">
                <strong>Services</strong>
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography
                className={clsx(classes.footerLink, classes.footerTitle)}
                onClick={() => navigate("/examples")}
                variant="subtitle2"
              >
                Examples
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography
                className={clsx(classes.footerLink, classes.footerTitle)}
                onClick={() => navigate("/tutorials")}
                variant="subtitle2"
              >
                Tutorials
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography className={clsx(classes.footerLink, classes.footerTitle)} onClick={() => navigate("/faq")} variant="subtitle2">
                FAQ
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <div style={{ marginBottom: 16 }}>
              <Typography className={classes.footerTitle} variant="subtitle2">
                <strong>AppMockUp</strong>
              </Typography>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Typography variant="subtitle2">Create the most compelling screenshots for your app.</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
